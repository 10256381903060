// * {
//   // ONLY FOR DEBUGGING OVERFLOWS
//   outline: 1px solid limegreen !important;
//   background: rgb(0 100 0 / 0.05) !important;
// }

html {
  /* CSS HEX */
  /* Color theme */
  --black: #000000ff;
  --dark-purple: #3d2645ff;
  --byzantium: #832161ff;
  --cerise: #da4167ff;
  --ghost-white: #f0eff4ff;
  --light-gray: #dfdbe5;
  --ultra-violet: #5f5aa2;
  --federal-blue: #190e4f;
  --YlnMn-blue: #355691;
  --english-violet: #413f54;

  --burgundy: #890620;
  --dark-green: #002a22;

  --primary-color: var(--dark-purple);
  --text-color: var(--ghost-white);
  --details-color: var(--light-gray);
  --disabled-color: var(--light-gray);
  --secondary-color: var(--byzantium);
  --ternary-color: var(--cerise);
  --error-color: var(--cerise);
  --icon-details-color: var(--cerise);
  --alt-primary-color: var(--ghost-white);
  --alt-text-color: var(--black);
  color: var(--text-color);

  --font-size-xxs: 0.4rem;
  --font-size-xs: 0.6rem;
  --font-size-sm: 0.8rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.2rem;
  --font-size-xl: 1.4rem;
  --font-size-xxl: 1.6rem;

  /* Layout */
  --author-bar-height: 30px;
  --flex-gap: 10px;
  --line-height-md: 1.5em;
  --section-internal-padding: 20px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
