.tabgroup__container {
  color: var(--text-color);
  background-color: var(--primary-color);
  border-radius: 10px;
  height: 100%;

  .tabgroup__button_group {
    display: flex;
    height: 40px;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 10px 10px 0 0;

    background-color: var(--secondary-color);

    & > button {
      all: unset;
      height: 20px;
      width: 100%;
      padding: 10px;
      cursor: pointer;
      font-weight: bolder;
      text-transform: uppercase;
      text-align: center;
      user-select: none;
      border-radius: 10px 10px 0 0;

      &:hover {
        background-color: var(--alt-primary-color);
        color: var(--alt-text-color);
      }
    }

    .tabgroup__selected {
      height: 100%;
      border-radius: 10px 10px 0 0;
      background-color: var(--primary-color);
      &:hover {
        background-color: var(--primary-color);
        color: var(--text-color);
      }
    }
  }

  & > hr {
    border-style: solid;
    border-color: var(--primary-color);
    border-width: 3px;
  }

  .tabgroup__content {
    & > div {
      height: 100%;
    }
  }
}
