.project-id__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "edit list" "edit list" "add list";
  gap: 10px;
  .project-version__edit {
    grid-area: edit;
  }
  .project-version__add {
    grid-area: add;
  }
  .project-version__list {
    grid-area: list;
  }
}
