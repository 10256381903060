.team-item {
  .team-item__name {
    .team-item__name-field {
      all: unset;
      cursor: pointer;
      font-weight: normal;
    }
  }
  .team-item__controls > span {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    gap: 2px;
  }
}
