.ticket-chart-container {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  background-color: var(--primary-color);
  background-color: var(--details-color);

  .ticket-chart__legend {
    padding: 10px;
    display: block;
    text-align: center;
    font-weight: bolder;
    font-size: var(--font-size-lg);
    color: var(--alt-text-color);
  }

  .ticket-chart__canvas {
    width: 100%;
    height: auto;
    padding: 20px;
  }
}
