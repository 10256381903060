.profile__container {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;

  .profile__data {
    height: 100%;
  }
  .profile__password {
    height: 100%;
  }
}
