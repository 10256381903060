.select {
  --select-color: var(--secondary-color);
  --select-bg-color: var(--alt-primary-color);

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  cursor: pointer;

  font-weight: bold;
  color: var(--select-color);
  background-color: var(--select-bg-color);

  border: 2px solid var(--secondary-color);
  border-radius: 4px;
  padding: 10px;
}
