.text-collapsed {
  display: none !important;
  width: 0 !important;
}

.layout-dimensions {
  grid-template-columns: 1fr 4fr;
}
.layout-dimensions-collapsed {
  grid-template-columns: 100px auto;
}

.layout {
  display: grid;
  grid-template-rows: calc(100vh - var(--author-bar-height)) var(
      --author-bar-height
    );
  grid-template-areas:
    "navbar content"
    "author author";

  .layout__navbar {
    grid-area: navbar;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--primary-color);
    gap: 20px;
  }

  .layout__content {
    grid-area: content;
    height: 100%;
    // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  .layout__author {
    grid-area: author;
    height: var(--author-bar-height);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-sm);
    gap: 10px;
    color: var(--alt-text-color);
    background-color: var(--details-color);

    * {
      display: flex;
      gap: 5px;
    }

    a {
      text-decoration: none;
      color: var(--alt-text-color);
      &:hover {
        color: var(--secondary-color);
      }
    }
  }
}
