.teams__container {
  height: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "teams-list users-list users-list"
    "teams-list users-list users-list"
    "create-team add-user add-user";

  .teams__list {
    grid-area: teams-list;
  }

  .teams__users-list {
    grid-area: users-list;
  }

  .teams__create-team {
    grid-area: create-team;
  }

  .teams__add-user {
    grid-area: add-user;
  }
}
