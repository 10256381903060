.membership-form__submit-success {
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  gap: 20px;
  font-weight: bolder;
  font-size: var(--font-size-lg);
}
.membership__legend {
  line-height: var(--line-height-md);
}
.membership__status-alert {
  font-weight: bold;
  color: var(--secondary-color);
}
.membership-form {
  padding: 20px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .membership-form__error {
    color: var(--error-color);
    font-weight: bolder;
  }
}
