.select {
  --btn-color: var(--secondary-color);
  --btn-bg-color: var(--alt-primary-color);

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  cursor: pointer;
  font-weight: 500;
  border: 2px solid var(--btn-color);
  border-radius: 10px;
  color: var(--btn-color);
  background-color: var(--btn-bg-color);
}
