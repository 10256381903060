.table-container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .table__caption {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    font-weight: bolder;
    min-height: 40px;
    border: 1px solid var(--primary-color);
    border-radius: 10px 10px 0 0;
    color: var(--text-color);
    background-color: var(--primary-color);
  }

  .table-scroll__container {
    width: 100%;
    border: 1px solid var(--primary-color);
    overflow-y: auto;
    overflow-x: hidden;
    .table {
      width: 100%;
      table-layout: fixed;
      overflow-wrap: break-word;
      height: auto;
      border-radius: 10px 10px 0 0;
      border-collapse: collapse;
      border: 1px solid var(--primary-color);
      background-color: var(--alt-primary-color);

      .table__header {
        position: sticky;
        top: 0; /* table head will be placed from the top of the table and sticks to it */
        background-color: var(--primary-color);
        color: var(--text-color);
        font-size: 0.9rem;

        .table__header-row {
          height: 40px;

          .table__header-row-entries {
            padding-left: 10px;

            background-color: var(--primary-color);
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            gap: 5px;
            .table__header-row-input {
              width: 5ch;
            }
            .table__header-tag {
              padding-left: 10px;
            }
          }
        }
        tr > th {
          text-align: center;
          vertical-align: middle;
        }
      }
      .table__body {
        color: var(--alt-text-color);
        tr > th {
          height: 40px;
          padding-left: 10px;
          text-align: left;
          vertical-align: middle;
          word-wrap: break-word;
          // word-break: break-all;
        }
      }
    }
  }

  .table__footer {
    display: flex;
    height: 40px;
    border: 1px solid var(--primary-color);
    color: var(--text-color);
    background-color: var(--primary-color);
    justify-content: space-between;
    align-items: center;
    .footer__left {
      padding-left: 10px;
    }
    .footer__right {
      padding-right: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > * {
        margin: 5px;
      }
    }
  }
  .table__extra-controls {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }

  .table__bottom {
    border-radius: 0 0 10px 10px;
  }
}
