.ticket-new__container {
  height: 100%;
  display: flex;
  justify-content: center;
}

.ticket-new__form {
  flex-grow: 1;
  max-width: 800px;
}
