.register-form__submit-success {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  font-weight: bolder;
  font-size: var(--font-size-lg);
}

.register-form {
  padding: 20px;
  width: 80%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 20px;

  .register-form__error {
    color: var(--error-color);
    font-weight: bolder;
  }
  .register-form__controls {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
  }
}
