.hide {
  display: none;
}

.invisible {
  visibility: hidden;
}

.navbar__legend {
  flex: 1;
  display: flex;
  justify-content: right;
  align-items: center;
  background-image: url("/assets/svgs/floating-cogs.svg");

  .navbar__legend-container {
    width: 100%;
    display: flex;

    .navbar__legend-title {
      margin-left: 25px;
      flex-grow: 3; // Title streches its width when navbar is open
      height: 50%;
      color: var(--primary-color);
      background-color: var(--text-color);
      border-radius: 50px 0 0 50px;
      display: flex;
      gap: 8px;
      font-size: 2rem;
      align-items: center;
      padding: 10px;

      .navbar__title-name {
        font-weight: 600;
      }
    }
    .navbar__btn-close {
      cursor: pointer;
      display: flex;
      align-items: center;

      color: var(--primary-color);
      background-color: var(--text-color);
      &:hover {
        color: var(--text-color);
        background-color: var(--primary-color);
      }

      .navbar__legend-icon {
        padding: 5px;
      }
    }
  }
}

.navbar__username {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: var(--font-size-xl);
  font-weight: bolder;
  .navbar__username-link {
    text-decoration: none;
    color: var(--ternary-color);
  }
}

.navbar__links-list {
  flex: 3;
}

.navbar__bottom {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .navbar__logout {
    display: flex;
    gap: 10px;
    --iconDisappearDisplay: inherit;
    --iconAppearDisplay: none;

    .doorClosedShow {
      display: var(--iconDisappearDisplay);
    }
    .doorOpenShow {
      display: var(--iconAppearDisplay);
    }
    &:hover {
      --iconDisappearDisplay: none;
      --iconAppearDisplay: inherit;
    }
  }
}
