.user-item__manager,
.user-item__owner {
  span {
    display: flex;
    justify-content: center;
  }
}

.user-item__controls {
  padding-right: 10px;
  span {
    display: flex;
    justify-content: right;
    gap: 2px;
  }
}
