.ticket-grid__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    // "details comments"
    // "history attachments";
    "details tabs";
  gap: 10px;

  .ticket-grid__element {
    overflow-y: hidden;
    & > div {
      height: 100%;
    }
  }
  .ticket__details {
    grid-area: details;
  }
  .ticket__tabs {
    grid-area: tabs;

    .tabs {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }
  // .ticket__comments {
  //   grid-area: comments;
  // }
  // .ticket__history {
  //   grid-area: history;
  // }
  // .ticket__attachment {
  //   grid-area: attachments;
  // }
}
