.textfield {
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  gap: 10px;

  font-weight: bold;

  input,
  textarea {
    border: 2px solid var(--secondary-color);
    border-radius: 4px;
  }

  textarea {
    padding: 10px;
    font-family: inherit;
    resize: none;
  }

  input:invalid,
  textarea:invalid {
    background-color: var(--light-gray);
  }
}

.txt-small {
  font-size: 0.8rem;
  input,
  textarea {
    padding: 5px;
  }
}
.txt-medium {
  input,
  textarea {
    padding: 10px;
  }
}
.txt-large {
  font-size: 1.2rem;
  input,
  textarea {
    padding: 15px;
  }
}
