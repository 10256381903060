.project-item {
  --card-color: var(--alt-primary-color);
  --card-text-color: var(--alt-text-color);
  color: var(--card-text-color);
  background-color: var(--card-color);
  cursor: pointer;

  th {
    padding: 10px;
  }

  &:hover {
    color: var(--alt-text-color);
    --card-color: var(--details-color);
    .chip {
      color: var(--text-color);
    }
  }
}
