.comments__container {
  .comments__create {
    padding: 5px;
    width: 100%;
    display: flex;
    justify-content: stretch;
    gap: 5px;
    .comment__add {
      flex-grow: 0;
    }

    .comment__message {
      flex-grow: 10;
    }

    .comment__commenter {
      display: none;
    }
  }
}
