.dashboard-grid__container {
  height: 100%;
  padding: 10px;
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  display: grid;
  background-color: var(--primary-color);
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;

  grid-template-areas:
    "controls controls status type"
    "controls controls status type"
    "controls controls severity priority";
  gap: 5px 5px;

  .dashboard-grid__element {
    display: flex;
    justify-content: center;
    color: var(--text-color);
    background-color: var(--primary-color);
  }

  .dashboard-grid__controls {
    grid-area: controls;
  }
  .dashboard-grid__priority {
    grid-area: priority;
  }
  .dashboard-grid__type {
    grid-area: type;
  }
  .dashboard-grid__status {
    grid-area: status;
  }
  .dashboard-grid__severity {
    grid-area: severity;
  }
}
