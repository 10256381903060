.chip {
  padding: 10px;
  border: 1px solid var(--alt-primary-color);
  border-radius: 15px;
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.ticket-item {
  height: 40px;
  --card-color: var(--alt-primary-color);
  --card-text-color: var(--alt-text-color);
  color: var(--card-text-color);
  background-color: var(--card-color);
  cursor: pointer;

  &:hover {
    color: var(--alt-text-color);
    --card-color: var(--details-color);
    .chip {
      color: var(--text-color);
    }
  }

  th.ticket-item__priority,
  th.ticket-item__severity {
    text-align: center;
  }

  .ticket-item__version {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 0 10px 0 0;
  }
}
