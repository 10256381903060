.public {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: var(--text-color);
  background-color: var(--primary-color);
  background-image: url("/assets/svgs/floating-cogs.svg");

  .public__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    gap: 20px;
    align-items: center;
    border: 2px solid var(--secondary-color);
    border-radius: 15px;
    background-color: var(--alt-primary-color);
    color: var(--alt-text-color);
    min-height: 30vh;
    width: 40vw;

    .public__card-title {
      font-size: 1.5rem;
    }

    .public__card-controls {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 10px;
    }
  }
}
