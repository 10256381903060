.loader-container {
  height: 100vh;
  color: var(--primary-color);
  background-color: var(--alt-primary-color);
  display: flex;
  place-content: center;
  place-items: center;
  font-weight: bolder;
  gap: 10px;
  font-size: 3rem;

  .loader-legend {
    .loader-legend__icon-slowest {
      --fa-animation-duration: 0.8s;
    }
    .loader-legend__icon-faster {
      --fa-animation-duration: 1s;
    }
    .loader-legend__icon-fastest {
      --fa-animation-duration: 1.2s;
    }
  }
}
