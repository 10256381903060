.btn {
  --btn-color: var(--secondary-color);
  --btn-bg-color: var(--alt-primary-color);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  font-weight: 500;
  border: 2px solid var(--btn-color);
  border-radius: 10px;
  color: var(--btn-color);
  background-color: var(--btn-bg-color);
  user-select: none;
  text-transform: uppercase;

  &:hover {
    color: var(--btn-bg-color);
    background-color: var(--btn-color);
  }
}

.btn-disabled {
  cursor: not-allowed;
  --btn-color: var(--alt-text-color);
  --btn-bg-color: var(--disabled-color);
  &:hover {
    color: var(--btn-color);
    background-color: var(--btn-bg-color);
  }
}

.btn-invert {
  --btn-color: var(--alt-primary-color);
  --btn-bg-color: var(--secondary-color);
}

.btn-bold {
  font-weight: bolder;
}

.btn-link {
  text-decoration: none;
  flex-wrap: nowrap;
}
.btn-small {
  padding: 5px 10px 5px 10px;
  font-size: 0.8rem;
}

.btn-medium {
  padding: 10px;
}

.btn-large {
  padding: 15px;
  font-size: 1.2rem;
}
.btn-component {
}
.btn-outline {
}
.btn-solid {
}
