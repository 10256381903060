.version-item {
  --card-color: var(--alt-primary-color);
  --card-text-color: var(--alt-text-color);
  color: var(--card-text-color);
  background-color: var(--card-color);

  td {
    padding: 10px;
  }

  &:hover {
    color: var(--alt-text-color);
    --card-color: var(--details-color);
    .chip {
      color: var(--text-color);
    }
  }
  .version-item__name {
    .version-item__name-field {
      all: unset;
      cursor: pointer;
      font-weight: normal;
    }
  }
  .version-item__controls > span {
    display: flex;
    gap: 2px;
    flex-wrap: nowrap;
    justify-content: right;
  }
  .version-item__tickets-total {
    text-align: center;
  }
}
