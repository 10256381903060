.card__container {
  display: flex;
  flex-direction: column;
  .card__title {
    display: flex;
    place-content: center;
    place-items: center;
    font-size: 1.6rem;
    font-weight: bolder;
    height: 40px;
    border: 1px solid var(--primary-color);
    border-radius: 10px 10px 0 0;
    color: var(--text-color);
    background-color: var(--primary-color);
  }
  .card__element-data {
    font-size: var(--font-size-sm);
  }

  .card__content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid var(--primary-color);
    display: grid;
    grid-gap: 20px;
    color: var(--alt-text-color);
    background-color: var(--alt-primary-color);
    padding: 20px;

    .card__element {
      .card__element-title {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
  .card__content-column {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;

    // .card__element:last-child {
    //   grid-column: 1 / 3; /* span from grid column line 1 to 3 (i.e., span 2 columns) */
    // }
  }
  .card__content-row {
    grid-auto-flow: column;
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;

    .card__element:last-child {
      grid-row: 1 / 3; /* same concept, but for rows */
    }
  }

  .card__footer {
    height: 40px;
    border: 1px solid var(--primary-color);
    border-radius: 0 0 10px 10px;
    color: var(--text-color);
    background-color: var(--primary-color);

    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
}
