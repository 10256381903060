.navbar-item {
  list-style: none;
  .navbar-item__link {
    width: 100%;
    display: grid;
    grid-template-columns: 40px auto;

    padding: 10px 30px;
    gap: 10px;
    color: var(--text-color);
    text-decoration: none;
    cursor: pointer;

    .navbar-item__link-icon {
      display: flex;
      justify-content: center;
    }
    .navbar-item__link-text {
      display: flex;
      align-items: center;
      font-size: var(--font-size-lg);
    }

    &:hover {
      color: var(--primary-color);
      background-color: var(--alt-primary-color);
    }
  }
  .highlight-item {
    color: var(--primary-color);
    background-color: var(--alt-primary-color);
  }
}
