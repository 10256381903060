.notification {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--alt-primary-color);
  border-radius: 15px;
  padding: 20px;
  color: var(--alt-text-color);
  background-color: var(--alt-primary-color);
  box-shadow: 10px 0 0 0px rgba(61, 38, 69, 1);
  -webkit-box-shadow: 10px 0 0 0px rgba(61, 38, 69, 1);
  -moz-box-shadow: 10px 0 0 0px rgba(61, 38, 69, 1);
}
