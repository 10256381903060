.error-container {
  height: 100vh;
  color: var(--primary-color);
  background-color: var(--alt-primary-color);
  display: flex;
  place-content: center;
  place-items: center;
  font-weight: bolder;
  gap: 10px;
  font-size: 3rem;

  .error-legend {
    .error-legend__icon-slowest {
      --fa-animation-duration: 0.8s;
    }
    .error-legend__icon-faster {
      --fa-animation-duration: 1s;
    }
    .error-legend__icon-fastest {
      --fa-animation-duration: 1.2s;
    }
  }
}

.flex-column {
  flex-direction: column;
  gap: 20px;
  & > div,
  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .error-nav {
      font-size: 0.4em;
    }
  }
}
